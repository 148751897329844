import moment, { Moment } from 'moment'
import * as R from 'ramda'
import { isNotNull } from './utils';

// Domain
export interface Day {
  month: number
  day: number
  year: number
}

export interface Time {
  hour: number
  minute: number
}

export interface Show {
  id: string
  day: Day
  time: Time
  movieId: MovieId
  cinemaId?: CinemaId
  screen: string
  tags: string[]
}
export abstract class Show {
  static create(cinemaId: CinemaId, day: string, time: string, movieId: string, tags: string[]=[], id = '', screen=''): Show {
    return {
      id,
      day: Days.fromDDMMYYYY(day),
      time: Times.fromString(time),
      movieId,
      cinemaId,
      screen,
      tags,
    }
  }
}

export type MovieId = string
export interface Movie {
  id: MovieId
  title: string
  slug: string
  excerpt: string
  description: string
  category: string[]
  cast: string[]
  directors: string[]
  duration: number | null
  releaseDate: Day | null
  trailerUrl: string | null
  picture: Image | null
  poster: Image
  is3D: boolean
  is4K: boolean
  isVM14: boolean
  isVM18: boolean
  event: SpecialEvent | null
  shows: {[day: string]: Show[]}
}

export interface SpecialEventDetail {
  dates: Day[]
  dateSummary: string
  tickets: string[]
}

export interface SpecialEvent extends CinemasMap<SpecialEventDetail | null> {
  type: string
}

export type CinemaId = 'mds' | 'odeon6'
export type CinemasMap<T> = {
  [K in CinemaId]: T
}

export interface Cinema {
  id: CinemaId
  name: string
  movies: Movie[]
  moviesComingSoon: Movie[]
  extraMovies: Movie[]
  banner: ImageLink | null
  slides: ImageLink[]
  backgroundImage: string | null
  showSlides: boolean
  heroImage: string | null
  noShowsMessage: string
  nowShowingRaw: string
  topMargin: number
  url: string
  description: string
  keywords: string
}

interface CinemaMeta {
  title: string
  url: string
  description: string
  keywords: string
  promotionDayMessage: string
  phone: string
  messenger: string
  logo: ImageSize
  creaCinemaId: string
}

export const CINEMA_META: CinemasMap<CinemaMeta> = {
  mds: {
    title: 'Multiplex delle Stelle',
    url: 'https://www.multiplexdellestelle.it',
    description: 'Il cinema di Città delle Stelle. 12 sale con Sony Digital 4K e 3D e schermi fino a 17 metri.',
    keywords: 'cinema, multiplex, film',
    logo: {
      name: 'standard',
      url: 'https://m1.multiplexdellestelle.it/wp-content/uploads/2019/04/mds-logo.png',
      width: 801,
      height: 349
    },
    promotionDayMessage: 'Martedi a €5',
    phone: '0736 815220',
    messenger: 'https://m.me/149938445075571',
    creaCinemaId: '5332',
  },
  odeon6: {
    title: 'Cinema Odeon 6',
    url: 'https://www.odeon6.it',
    description: 'Il cinema di Ascoli Piceno. 6 sale nel centro della città!',
    keywords: 'cinema, film, ascoli piceno, ap',
    // TODO need higher res image here
    logo: {
      name: 'standard',
      url: 'https://m1.multiplexdellestelle.it/wp-content/uploads/2019/04/logo-odeon6.png',
      width: 273,
      height: 132
    },
    promotionDayMessage: 'Lunedi a €4',
    phone: '0736 781690',
    messenger: 'https://m.me/cinemaodeon6',
    creaCinemaId: '5560',
  }
}

// UI

export interface Image {
  mainColor?: string
  tinyThumbnail?: string
  sizes: ImageSize[]
}

export interface ImageSize {
  url: string
  name: string
  width?: number
  height?: number
}

export interface ImageLink {
  image: Image
  link: string | null
}

export interface HomePage {
  id: 'home'
  highlight: Movie | null
  selectedDay: Day
}

export interface ProgrammazionePage {
  id: 'programmazione'
  highlight: Movie | null
  selectedDay: Day
}

export interface MoviePage {
  id: 'movie'
  movie: Movie
  selectedDay: Day | null
  selectedCinema: CinemaId | null
  cinemaShows: CinemasMap<Show[]>
}

export interface EventsPage {
  id: 'events',
  sortBy: 'TYPE' | 'DATE'
}

export interface Model {
  page: HomePage | ProgrammazionePage | MoviePage | EventsPage
  isSSR: boolean
  now: Moment
  nowShowing: Movie[]
  comingSoon: Movie[]
  extraMovies: Movie[]
  events: Movie[]
  isUpdating: boolean
  session: {
    started: number
    lastUpdated: number
  }
}

const allShows =  (movie: Movie): Show[] => {
  return R.values(movie.shows).reduce((acc, s) => [...acc, ...s], [])
}

const isFutureEvent = (day: Day) => (movie: Movie): boolean => {
  if (movie.event == null) {
    // if it's not an event we let it pass the filter
    return true
  }
  const futureShows = R.keys(CINEMA_META).reduce( (acc, cId) => {
    return [...acc, ...movie.event![cId]!.dates.filter(showDay => Days.isEqualOrAfter(day)(showDay))]
  }, [])
  return futureShows.length > 0
}

export const MESSAGE_MOVIE_SLUGS = ['chiusura-settimanale']

// Utility functions
export const Movies = {
  getShows: (movie: Movie, day: Day | null, cinemaId: CinemaId | null): Show[] => {
    const dayFilter = (show: Show): boolean =>{
      return day == null
        ? true
        : Days.isEqual(day)(show.day)
    }
    const cinemaFilter = (show: Show): boolean => {
      return cinemaId == null
        ? true
        : show.cinemaId != null && show.cinemaId == cinemaId
    }
    return allShows(movie).filter(dayFilter).filter(cinemaFilter)
  },
  allShows: allShows,
  groupByDay: (movies: Movie[]): {[d: string] : Movie[]} => {
    return movies.reduce( (acc, m) => {
      const movieDays = R.keys(m.shows)
      movieDays.forEach( md => {
        acc[md] = acc[md] || []
        acc[md].push(m)
      })
      return acc
    }, {})
  },
  getPresentAndFutureDays: (reference: Day, movies: Movie[]): Day[] => {
    return R.keys(Movies.groupByDay(movies))
      .map(Days.fromString)
      .filter(Days.isEqualOrAfter(reference))
      .sort(Days.difference)
  },
  getShowsOnOrAfter: (reference: Day) => (movie: Movie): {[d: string] : Movie[]} => {
    const isOnOrAfter = Days.isAfter(Days.add(reference, -1))
    return R.keys(movie.shows).reduce( (acc, day: string) => {
      return isOnOrAfter(Days.fromString(day))
        ? {
            ...acc,
           [day]: movie.shows[day]
          }
        : acc
    }, {})
  },
  hasActiveShows: (day: Day) => (movie: Movie): boolean => {
    return R.keys(movie.shows)
      .map(Days.fromString)
      .filter(Days.isEqualOrAfter(day))
      .length > 0
  },
  hasShowsOn:  (day: Day) => (movie: Movie): boolean => {
    return movie.shows[Days.toString(day)] != null && movie.shows[Days.toString(day)].length > 0
  },
  eventDays: (movie: Movie): Day[] => {
    const mdsDates = movie.event != null
      ? movie.event.mds !== null
        ? movie.event.mds.dates
        : []
      : []
    const odeon6Dates = movie.event != null
      ? movie.event.odeon6 !== null
        ? movie.event.odeon6.dates
        : []
      : []
    return R.uniqBy(Days.toString, [...mdsDates, ...odeon6Dates]).sort(Days.difference)
  },
  getTrailerYoutubeID: (trailerUrl: string | null): string => {
    return (trailerUrl || '').split("=")[1]
  },
  getEmbedUrl: (trailerUrl: string | null): string => {
    // Assuming youtube
    // A bit hacky to keep it simple
    // TODO: use URL again, for some reason Netlify fails the build with it
    // const videoId = new URL(trailerUrl || "").searchParams.get('v')
    const videoId = Movies.getTrailerYoutubeID(trailerUrl)
    return videoId == null
      ? ''
      : 'https://www.youtube.com/embed/' + videoId + '?modestbranding=1'
  },
  sortNowShowingMovies: (day: Day | null) => (first: Movie, second: Movie): number => {
    const sDay = day == null ? null : Days.toString(day)
    return first.event != null
      ? -1
      : second.event != null
        ? 1
        : sDay != null
          ? (second.shows[sDay].length - first.shows[sDay].length)
          : Days.difference(second.releaseDate, first.releaseDate)
            || first.title.toLowerCase().localeCompare(second.title.toLowerCase())
  },
  computeComingSoon: (comingSoonCandidates: Movie[], today: Day, nowShowingMovieIds: string[]): Movie[] => {
    return R.uniqBy(m => m.id, comingSoonCandidates)
      .filter( m => !nowShowingMovieIds.includes(m.id) )
      .filter(isFutureEvent(today))
      .filter(m => m.releaseDate == null || Days.isAfter(today)(m.releaseDate))
      .sort( (a, b) => Days.difference(a.releaseDate, b.releaseDate))
  }
}
export const getShowsOnDay = (day: Day) => (m: Movie): Show[] => {
  return m.shows[Days.toString(day)] || []
}

const isShowingOn = (day: Day) => (show: Show): boolean => {
  return Shows.isOnDay(day)(show)
}

const isAfter = (nMins: number) => (reference: Moment) => (show: Show): boolean => {
  const a = Days.toMoment(show.day).hour(show.time.hour).minute(show.time.minute)
  const minutes = reference.diff(a, 'minutes')
  return minutes < nMins
}

export const Shows = {
  groupByMovie: (shows: Show[]): {[m: string]: Show[]} => {
    return shows.reduce( (acc, s) => {
      acc[s.movieId] = acc[s.movieId] || []
      acc[s.movieId].push(s)
      return acc
    }, {})
  },
  isOnDay: (day: Day) => (show: Show): boolean => {
    return Days.isEqual(show.day)(day)
  },
  filterByMovie: (movieId: MovieId, shows: Show[]): Show[] => (
    shows.filter(s => s.movieId == movieId)
  ),
  isAfter,
  isAfter20Mins: isAfter(20),
  isShowingOn,
  isShowingToday: (shows: Show[]) => isShowingOn(Days.fromDate(Date.now())),
  toIsoString: (show: Show) => (
    Days.toMoment(show.day).hour(show.time.hour).minute(show.time.minute).toISOString()
  )
}

export const Times = {
  toString: (time: Time): string => {
    return time.hour + ":" + padded(time.minute)
  },
  now: (): Time => {
    const now = moment()
    return {
      hour: now.hour(),
      minute: now.minute()
    }
  },
  isBefore: (reference: Time) => (t: Time): boolean => {
    return (reference.hour > t.hour) || ((reference.hour === t.hour) && (reference.minute > t.minute))
  },
  isAfter: (reference: Time) => (t: Time): boolean => {
    return (reference.hour < t.hour) || ((reference.hour === t.hour) && (reference.minute < t.minute))
  },
  create: (hour: number, minute: number): Time => {
    return {
      hour: hour,
      minute: minute
    }
  },
  fromString: (time: string): Time => {
    const m = moment(time, "h:mm")
    return {
      hour: m.hour(),
      minute: m.minute()
    }
  }
}

const fromDate = (date: number): Day => {
  const m = moment(date)
  return {
    day: m.date(),
    month: m.month() + 1,
    year: m.year()
  }
}

const fromDDMMYYYY = (day: string): Day => {
  const tokens = day.split('/')
  return {
    day: parseInt(tokens[0]),
    month: parseInt(tokens[1]),
    year: parseInt(tokens[2])
  }
}

const toMoment = (day: Day): Moment => {
  return moment({
    year:day.year,
    month: day.month - 1,
    day: day.day,
    hour: 0,
    minute: 0
  })
}

const fromMoment = (m: Moment): Day => {
  return {
    day: m.date(),
    month: m.month() + 1,
    year: m.year()
  }
}

const isDayEqual = (day1: Day) => (day2: Day): boolean => {
  return day1.day === day2.day
    && day1.month === day2.month
    && day1.year === day2.year
}

const isDayAfter = (reference: Day) => (toTest: Day): boolean => {
  return toMoment(toTest).isAfter(toMoment(reference))
}

const WEEK_DAYS = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
const WEEK_DAYS_LONG = [
  'Domenica',
  'Lunedi',
  'Martedi',
  'Mercoledi',
  'Giovedi',
  'Venerdi',
  'Sabato'
]

const MILLIS_IN_DAY = 86400000
export const Days = {
  fromMoment: fromMoment,
  toMoment: toMoment,
  fromDDMMYYYY: fromDDMMYYYY,
  fromMMDDYYYY: (day: string): Day => {
    const tokens = day.split('/')
    return {
      day: parseInt(tokens[1]),
      month: parseInt(tokens[0]),
      year: parseInt(tokens[2])
    }
  },
  fromDate: fromDate,
  isEqual: isDayEqual,
  isAfter: isDayAfter,
  isEqualOrAfter: (reference: Day) => (toTest: Day): boolean => {
    return isDayEqual(reference)(toTest) || isDayAfter(reference)(toTest)
  },
  isBefore: (reference: Day) => (toTest: Day): boolean => {
    return toMoment(toTest).isBefore(toMoment(reference))
  },
  toMilliseconds: (day: Day): number => {
    return Days.toMoment(day).toDate().getMilliseconds()
  },
  getEarliest: (days: Array<Day | null>): Day | null => {
    return days
      .filter(isNotNull)
      .reduce((acc, d) => {
        return acc == null
          ? d
          : Days.isBefore(acc!)(d)
            ? d
            : acc
      }, null)
  },
  difference: (day1: Day | null, day2: Day | null): number => {
    return day1 == null
      ? 1
      : day2 == null
        ? -1
        : Days.toMoment(day1).diff(Days.toMoment(day2)) / MILLIS_IN_DAY
  },
  add: (day: Day, numberOfDays: number): Day => {
    return fromMoment(toMoment(day).add(numberOfDays, "day"))
  },
  format: (day: Day, format: string): string => {
    return toMoment(day).format(format)
  },
  weekDay: (day: Day): string => {
    return WEEK_DAYS[toMoment(day).format('d')] || ''
  },
  weekDayLong: (day: Day): string => {
    return WEEK_DAYS_LONG[toMoment(day).format('d')] || ''
  },
  toString: (day: Day): string => {
    return padded(day.day) + '/' + padded(day.month) + '/' + padded(day.year)
  },
  toPrettyString: (day: Day, today: Day): string => {
    const tomorrow = Days.add(today, 1)
    return Days.isEqual(day)(today)
      ? `Oggi ${day.day}/${day.month}`
      : Days.isEqual(day)(tomorrow)
        ? `Domani ${day.day}/${day.month}`
        : `${Days.weekDay(day)} ${day.day}/${day.month}`
  },
  fromString: fromDDMMYYYY
}

const padded = (n: number): string => {
  return n > 9 ? String(n) : `0${n}`
}


